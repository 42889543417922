import React, {useState, useEffect} from "react";
import {debounce, classList} from "../../../utils";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import {NavLink} from "react-router-dom";
import ScrollTo from "../../common/ScrollTo";
import {Button, useMediaQuery} from "@material-ui/core";
import {useTheme} from "@material-ui/core/styles";

const TopBar4 = (props) => {
    const [isTop, setIsTop] = useState(true);
    const [isClosed, setIsClosed] = useState(true);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    let scrollableElement = document.querySelector(".scrollable-content");
    if (!scrollableElement) scrollableElement = window;

    let handleScrollRef = null;
    let toggleIcon = isClosed ? "menu" : "close";

    const handleScroll = () => {
        return debounce(({target: {scrollTop}}) => {
            let isCurrentTop = scrollTop < 100 || scrollableElement.scrollY < 100;
            setIsTop(isCurrentTop);
        }, 20);
    };

    handleScrollRef = handleScroll();

    const close = () => {
        setIsClosed(false);
    };

    function refreshPage() {
        window.location.href = '/help';
    }

    useEffect(() => {
        if (isMobile) {
            setIsClosed(true);
        }
    }, [isMobile]);

    useEffect(() => {
        if (!isMobile) {
            setIsClosed(false);
        }
        if (scrollableElement) {
            scrollableElement.addEventListener("scroll", handleScrollRef);
        }

        return () => {
            if (scrollableElement) {
                scrollableElement.removeEventListener("scroll", handleScrollRef);
            }
        };
    }, [scrollableElement, handleScrollRef]);

    return (
        <section
            className={classList({
                header: true,
                "bg-transparent": isTop && !isMobile,
                "header-fixed": !isTop,
                closed: isClosed,
            })}
        >
            <div className="container header-container">
                <div className="brand">
                    <a href={"/"}>  <img style={{borderRadius: "13px"}} src="/assets/images/da/web_logo.png" alt=""/></a>
                </div>
                {!props.otherMenus ?
                    <div className="float-right">
                        <ul className="navigation">
                            <li>
                                <ScrollTo to="intro4" onScroll={close}>
                                    Home
                                </ScrollTo>
                            </li>
                            <li>
                                <ScrollTo to="Features2" onScroll={close}>
                                    How it works
                                </ScrollTo>
                            </li>
                            <li>
                                <ScrollTo to="feature4" onScroll={close}>
                                    Key Features
                                </ScrollTo>
                            </li>


                        </ul>
                    </div>
                    :
                    <div className="float-right">
                        <ul className="navigation">
                            <li>
                                <NavLink onScroll={close} to={"/"}>Home</NavLink>
                            </li>
                            <li>
                                <NavLink onScroll={close} to={"/terms"}>Terms of Use</NavLink>
                            </li>
                            <li>
                                <NavLink onScroll={close} to={"/privacy-policy"}>Privacy Policy</NavLink>
                            </li>
                        </ul>
                    </div>
                }
                <div className="m-auto"/>
                <IconButton
                    className="header__toggle"
                    onClick={() => {
                        setIsClosed(!isClosed);
                    }}
                >
                    <Icon>{toggleIcon}</Icon>
                </IconButton>
            </div>
        </section>
    );
};

export default TopBar4;
