import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
    Icon,
    Grid,
    Button,
    TextField,
    InputAdornment,
} from "@material-ui/core";
import {darken} from "@material-ui/core/styles";
import FacebookIcon from "../../common/icons/FacebookIcon";
import TwitterIcon from "../../common/icons/TwitterIcon";
import InstagramIcon from "../../common/icons/InstagramIcon";
import LinkedinIcon from "../../common/icons/LinkedinIcon";
import clsx from "clsx";

const useStyles = makeStyles(({palette, ...theme}) => ({
    section: {
        background: "#E71580",
        color: palette.primary.contrastText,
    },
    termsTop: {
        marginTop: "18px"
    },
    iconWrapper: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        padding: 4,
        borderRadius: 4,
        marginRight: 12,
        border: `1px solid ${palette.primary.contrastText}`,
        cursor: "pointer",
    },
    link: {
        borderRadius: 4,
        "&:hover": {
            background: darken("#E71580", 0.2),
        },
    },
}));

const companyOption = ["About Us", "Services", "Team", "Pricing", "Project"];

const userfulLink = [
    "Terms of Services",
    "Privacy Policy",
    "Documentation",
    "Changelog",
    "Components",
];

const Footer2 = () => {
    const classes = useStyles();

    return (
        <section className={`section ${classes.section}`} id="contact4">
            <div className="container">
                <Grid container spacing={3}>
                    <Grid item lg={9} md={6} sm={6} xs={12}>
                        <div className="footer1__about">
                            <h4 className="text-24 font-normal m-0">About XpressDOTS App</h4>
                            <p className="my-6 max-w-500 text-inherit">
                            Experience the future of product management with XpressDOTS, the ultimate all-in-one CMS application. Our platform is meticulously crafted to simplify your product distribution, empower brand control, and optimize sales team assignments. With XpressDOTS, you gain the unparalleled ability to effortlessly create, manage, and distribute your products. Let's explore the incredible features and capabilities that set our app apart.
                            </p>
                            <div className={classes.termsTop}>
                                <div>
                                    For support, please go through our <a style={{color: "white"}}
                                                                          href="/">FAQ</a> section or write
                                    to <a style={{color: "white"}}
                                          href="mailto:support@xpressdots.com"> support@xpressdots.com</a>
                                </div>
                            </div>
                            <div className={classes.termsTop}>
                                <div>Copyright &#169;  2023 XpressDOTS. All Rights Reserved</div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <div className="flex flex-wrap">
                            <div className={classes.iconWrapper}>
                                <FacebookIcon className="text-13" fontSize="small"/>
                            </div>
                            <div className={classes.iconWrapper}>
                                <InstagramIcon className="text-13" fontSize="small"/>
                            </div>
                        </div>
                        <div className={classes.termsTop}>
                            <div><a href={"/terms"}>Terms of Use</a></div>
                            <div><a href={"/privacy-policy"}>Privacy Policy</a></div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </section>
    );
};

export default Footer2;
