import React from "react";
import {Grid, useMediaQuery} from "@material-ui/core";

const PrivacyPolicyWeb = () => {
    const isMobile = useMediaQuery("(max-width: 768px");

    return (
        <section className="section">
            <div className="container">
                <Grid container spacing={isMobile ? 3 : 10} alignItems="top">
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <h1 className="mt-20 font-normal text-22">XpressDOTS Privacy Policy</h1>
                        <p>
                            F12 Technologies (P) Ltd. built the XpressDOTS as a Free app. This SERVICE is at no cost and is intended
                            for use as is. This page is used to inform visitors regarding our policies with the
                            collection, use, and disclosure of Personal Information if anyone decided to use our
                            Service. If you choose to use our Service, then you agree to the collection and use of
                            information in relation to this policy. The Personal Information that we collect is used for
                            providing and improving the Service. We will not use or share your information with anyone
                            except as described in this Privacy Policy. The terms used in this Privacy Policy have the
                            same meanings as in our Terms and Conditions, which are accessible at XpressDOTS unless
                            otherwise defined in this Privacy Policy.

                        </p>

                        <div>
                            <p className="text-primary m-0 text-20">Information Collection and Use</p>
                            <div className="flex items-center mb-4 ">
                                <p>
                                    For a better experience while using our Service, we may require you to provide us
                                    with certain personally identifiable information, including but not limited to users
                                    name, email address, gender, location, pictures. The information that we request
                                    will be retained by us and used as described in this privacy policy.
                                    The app does use third party services that may collect information used to identify
                                    you.
                                </p>
                            </div>
                        </div>

                        <div>
                            <p className="text-primary m-0 text-20">Device Information</p>
                            <div className="flex items-center mb-4">
                                <p className="my-2">
                                    We collect information from your device in some cases. The information will be
                                    utilized for the provision of better service and to prevent fraudulent acts.
                                    Additionally, such information will not include that which will identify the
                                    individual user.
                                </p>
                            </div>
                        </div>
                        <div>
                            <p className="text-primary m-0 text-20">Cookies</p>
                            <div className="flex items-center mb-4">
                                <p className="my-2">
                                    Cookies are files with small amount of data that is commonly used an anonymous
                                    unique identifier. These are sent to your browser from the website that you visit
                                    and are stored on your devices’s internal memory.

                                    This Services does not uses these “cookies” explicitly. However, the app may use
                                    third party code and libraries that use “cookies” to collection information and to
                                    improve their services. You have the option to either accept or refuse these
                                    cookies, and know when a cookie is being sent to your device. If you choose to
                                    refuse our cookies, you may not be able to use some portions of this Service.
                                </p>
                            </div>
                        </div>
                        <div>
                            <p className="text-primary m-0 text-20">
                                Location Information</p>
                            <div className="flex items-center mb-4">
                                <p className="my-2">
                                    Some of the services may use location information transmitted from users' mobile
                                    phones. We only use this information within the scope necessary for the designated
                                    service.
                                </p>
                            </div>
                        </div>
                        <div>
                            <p className="text-primary m-0 text-20">Service Providers</p>
                            <div className="flex items-center mb-4">
                                <p className="my-2">
                                    <p>
                                        We may employ third-party companies and individuals due to the following
                                        reasons:
                                    </p>
                                    <p>
                                        <ul>
                                            <li>To facilitate our Service;</li>
                                            <li>To provide the Service on our behalf;</li>
                                            <li>To perform Service-related services; or</li>
                                            <li>To assist us in analyzing how our Service is used.</li>
                                        </ul>
                                    </p>
                                    <p>
                                        We want to inform users of this Service that these third parties have access to
                                        their Personal Information. The reason is to perform the tasks assigned to them
                                        on our behalf. However, they are obligated not to disclose or use the
                                        information for any other purpose.
                                    </p>
                                </p>
                            </div>
                        </div>

                        <div>
                            <p className="text-primary m-0 text-20">Security</p>
                            <div className="flex items-center mb-4">
                                <p className="my-2">
                                    We value your trust in providing us your Personal Information, thus we are
                                    striving to use commercially acceptable means of protecting it. But remember
                                    that no method of transmission over the internet, or method of electronic
                                    storage is 100% secure and reliable, and we cannot guarantee its absolute
                                    security.
                                </p>
                            </div>
                        </div>

                        <div>
                            <p className="text-primary m-0 text-20">Links to Other Sites
                            </p>
                            <div className="flex items-center mb-4">
                                <p className="my-2">
                                    This Service may contain links to other sites. If you click on a third-party
                                    link, you will be directed to that site. Note that these external sites are not
                                    operated by us. Therefore, we strongly advise you to review the Privacy Policy
                                    of these websites. We have no control over and assume no responsibility for the
                                    content, privacy policies, or practices of any third-party sites or services.

                                </p>
                            </div>
                        </div>

                        <div>
                            <p className="text-primary m-0 text-20">Children’s Privacy
                            </p>
                            <div className="flex items-center mb-4">
                                <p className="my-2">
                                    We do not knowingly collect personally identifiable information from children.
                                    We encourage all children to never submit any personally identifiable
                                    information through the Application and/or Services. We encourage parents and
                                    legal guardians to monitor their children's Internet usage and to help enforce
                                    this Policy by instructing their children never to provide personally
                                    identifiable information through the Application and/or Services without their
                                    permission. If you have reason to believe that a child has provided personally
                                    identifiable information to us through the Application and/or Services, please
                                    contact us. You must also be at least 16 years of age to consent to the
                                    processing of your personally identifiable information in your country (in some
                                    countries we may allow your parent or guardian to do so on your behalf).
                                </p>
                            </div>
                        </div>

                        <div>
                            <p className="text-primary m-0 text-20">Changes to This Privacy Policy
                            </p>
                            <div className="flex items-center mb-4">
                                <p className="my-2">
                                    We may update our Privacy Policy from time to time. Thus, you are advised to review
                                    this page periodically for any changes. We will notify you of any changes by posting
                                    the new Privacy Policy on this page.
                                    This policy is effective as of 2023-01-01
                                </p>
                            </div>
                        </div>

                        <div>
                            <p className="text-primary m-0 text-20">Contact Us

                            </p>
                            <div className="flex items-center mb-4">
                                <p className="my-2">
                                    If you have any questions or suggestions about our Privacy Policy, do not hesitate
                                    to contact us at <a className="text-primary"
                                                        href="mailto:support@xpressdots.com">support@xpressdots.com</a>

                                </p>
                            </div>
                        </div>
                    </Grid>

                </Grid>
            </div>
        </section>
    );
};

export default PrivacyPolicyWeb;
