import React from "react";

const TopBarMobile = () => {
    return (
        <div style={{textAlign: "center"}}>
            <a href={"/"}>
                <img width="200px" style={{borderRadius: "13px"}} src="/assets/images/da/web_logo.png" alt=""/>
            </a>
        </div>
    );
};

export default TopBarMobile;