import React from "react";
import { makeStyles } from "@material-ui/core/styles";



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const useStyles = makeStyles((theme) => ({
  banner: {
    width: "70px",
    padding: "10px",
  },

}));

const Banner = () => {
  const classes = useStyles();

  return (
    <div  id="intro4">
        <img style={{borderRadius: "100px", width: "14%"}} src="/assets/images/da/web_logo.png" alt="img" className={classes.banner}  />
    </div>
  );
};

export default Banner;
