import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, useMediaQuery} from "@material-ui/core";
import Feature4IconCreator from "../../common/icons/Feature4IconCreator";
import CubeIcon from "../../common/icons/CubeIcon"
import Group from '@material-ui/icons/Group';
import SportsBaseballIcon from '@material-ui/icons/SportsBaseball';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PieChart from '@material-ui/icons/PieChart';
import HourglassEmpty from '@material-ui/icons/HourglassEmpty';
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';

const useStyles = makeStyles((theme) => ({
    feature: {
        [theme.breakpoints.down("xs")]: {
            textAlign: "center !important",
        },
    },

    musicImage: {
        width: "362px",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },

    musicImageWrapper: {
        position: "relative",
        textAlign: "right",

        // marginLeft: "100px",
        "&::before": {
            content: '" "',
            position: "absolute",
            display: "block",
            height: "81%",
            top: "11%",
            left: 0,
            right: 0,
            margin: "0 -2000px 0 -80px",
            borderRadius: "300px",
            background: theme.palette.primary.main,
            zIndex: -1,
            opacity: 0.1,

            [theme.breakpoints.down("xs")]: {
                display: "none",
            },
        },

        "&::after": {
            content: '" "',
            position: "absolute",
            display: "block",
            height: 36,
            width: "80%",
            marginTop: 32,
            left: "calc(10% + 20px)",
            opacity: 0.75,
            background:
                "radial-gradient(ellipse at center,  rgba(0,0,0,0.65) -25%,rgba(0,0,0,0) 55%);",
        },

        [theme.breakpoints.down("sm")]: {
            display: "block",
            "&::after": {
                display: "none",
            },
            "&::before": {
                display: "none",
            },
        },
    },
}));

const Feature4 = () => {
    const classes = useStyles();

    const isMobile = useMediaQuery("(max-width: 768px");

    return (
        <section className="section" id="feature4">
            <div className="container">
                <Grid container spacing={isMobile ? 3 : 10} alignItems="center">
                    <Grid item lg={8} md={6} sm={6} xs={12}>
                        <div className="max-w-900 mb-16">
                            <h1 className="mt-0 font-normal text-40">XpressDOTS Web Application</h1>
                            <p>
                                Empowering Administrators and Office Staff
                            </p>
                        </div>


                        <Grid container spacing={isMobile ? 5 : 8}>
                            <Grid
                                item
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className={classes.feature}
                            >
                                <div className="relative mx-4">
                                    <Feature4IconCreator>
                                        <Group color="primary" className="text-54"/>
                                    </Feature4IconCreator>
                                </div>
                                <div>
                                    <h4 className="mt-6 font-normal mb-0">User and Roles Management</h4>
                                    <h6 className="mt-2 font-normal mb-0"><i>Easily manage user access and roles, ensuring a
                                        secure and organized system.</i></h6>
                                </div>
                            </Grid>

                            <Grid
                                item
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className={classes.feature}
                            >
                                <div className="relative mx-4">
                                    <Feature4IconCreator>
                                        <PieChart color="primary" className="text-54"/>
                                    </Feature4IconCreator>
                                </div>
                                <div>
                                    <h4 className="mt-6 font-normal mb-0">
                                        Manage Linked Stores
                                    </h4>
                                    <h6 className="mt-2 font-normal mb-0"><i>Seamlessly connect and oversee stores associated with your
                                        distributor.</i></h6>
                                </div>
                            </Grid>

                            <Grid
                                item
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className={classes.feature}
                            >
                                <div className="relative mx-4">
                                    <Feature4IconCreator>
                                        <AssignmentIcon color="primary" className="text-54"/>
                                    </Feature4IconCreator>
                                </div>
                                <div>
                                    <h4 className="mt-6 font-normal mb-0">Manage Routes
                                    </h4>
                                    <h6 className="mt-2 font-normal mb-0"><i>Streamline store assignments to salespeople by creating and linking
                                        routes.</i></h6>
                                </div>
                            </Grid>

                            <Grid
                                item
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className={classes.feature}
                            >
                                <div className="relative mx-4">
                                    <Feature4IconCreator>
                                        <AssessmentIcon color="primary" className="text-54"/>
                                    </Feature4IconCreator>
                                </div>
                                <div>
                                    <h4 className="mt-6 font-normal mb-0">Manage Product Categories
                                    </h4>
                                    <h6 className="mt-2 font-normal mb-0"><i>Efficiently categorize and control your product
                                        offerings.</i></h6>
                                </div>
                            </Grid>

                            <Grid
                                item
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className={classes.feature}
                            >
                                <div className="relative mx-4">
                                    <Feature4IconCreator>
                                        <HourglassEmpty color="primary" className="text-54"/>
                                    </Feature4IconCreator>
                                </div>
                                <div>
                                    <h4 className="mt-6 font-normal mb-0">Manage Brands & Products
                                    </h4>
                                    <h6 className="mt-2 font-normal mb-0"><i> Create, edit, and manage product brands and offerings.</i></h6>
                                </div>
                            </Grid>

                            <Grid
                                item
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className={classes.feature}
                            >
                                <div className="relative mx-4">
                                    <Feature4IconCreator>
                                        <AssignmentTurnedIn color="primary" className="text-54"/>
                                    </Feature4IconCreator>
                                </div>
                                <div>
                                    <h4 className="mt-6 font-normal mb-0">
                                        Manage Product Attributes
                                    </h4>
                                    <h6 className="mt-2 font-normal mb-0"><i> Customize product attributes such as size and color
                                        with different prices.</i></h6>
                                </div>
                            </Grid>
                            <Grid
                                item
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className={classes.feature}
                            >
                                <div className="relative mx-4">
                                    <Feature4IconCreator>
                                        <AssignmentTurnedIn color="primary" className="text-54"/>
                                    </Feature4IconCreator>
                                </div>
                                <div>
                                    <h4 className="mt-6 font-normal mb-0">
                                        Purchase and Stock Management
                                    </h4>
                                    <h6 className="mt-2 font-normal mb-0"><i> Stay on top of product stocks and purchases.</i></h6>
                                </div>
                            </Grid>
                            <Grid
                                item
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className={classes.feature}
                            >
                                <div className="relative mx-4">
                                    <Feature4IconCreator>
                                        <AssignmentTurnedIn color="primary" className="text-54"/>
                                    </Feature4IconCreator>
                                </div>
                                <div>
                                    <h4 className="mt-6 font-normal mb-0">
                                        Manage Assignments
                                    </h4>
                                    <h6 className="mt-2 font-normal mb-0"><i>  Assign and track order and delivery tasks to sales staff with
                                        ease.</i></h6>
                                </div>
                            </Grid>
                            <Grid
                                item
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className={classes.feature}
                            >
                                <div className="relative mx-4">
                                    <Feature4IconCreator>
                                        <AssignmentTurnedIn color="primary" className="text-54"/>
                                    </Feature4IconCreator>
                                </div>
                                <div>
                                    <h4 className="mt-6 font-normal mb-0">
                                        Manage Orders
                                    </h4>
                                    <h6 className="mt-2 font-normal mb-0"><i>  Full-fledged order and status management, including status updates
                                        until payment.</i></h6>
                                </div>
                            </Grid>
                            <Grid
                                item
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className={classes.feature}
                            >
                                <div className="relative mx-4">
                                    <Feature4IconCreator>
                                        <AssignmentTurnedIn color="primary" className="text-54"/>
                                    </Feature4IconCreator>
                                </div>
                                <div>
                                    <h4 className="mt-6 font-normal mb-0">
                                        Payments & Invoice Management
                                    </h4>
                                    <h6 className="mt-2 font-normal mb-0"><i>Generate invoices and keep track of pending
                                        payments.</i></h6>
                                </div>
                            </Grid>

                            <Grid
                                item
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className={classes.feature}
                            >
                                <div className="relative mx-4">
                                    <Feature4IconCreator>
                                        <AssignmentTurnedIn color="primary" className="text-54"/>
                                    </Feature4IconCreator>
                                </div>
                                <div>
                                    <h4 className="mt-6 font-normal mb-0">
                                        Reports
                                    </h4>
                                    <h6 className="mt-2 font-normal mb-0"><i>Easily generate various reports, including order and invoice reports.
                                    </i></h6>
                                </div>
                            </Grid>
                        </Grid>


                    </Grid>
                    <Grid item lg={4} md={6} sm={6} xs={12} className={classes.feature}>
                        <div className={classes.musicImageWrapper}>
                            <img
                                className={classes.musicImage}
                                src="/assets/images/da/dashboard-1.png"
                                alt="mobile-1"
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </section>
    );
};

export default Feature4;
