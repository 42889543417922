import React from "react";
import {makeStyles, lighten} from "@material-ui/core/styles";
import {Grid, useMediaQuery, Icon, Button, Fab, Tooltip} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import AppleIcon from "../../common/icons/TwitterIcon";
import SportsHockeyIcon from '@material-ui/icons/SportsHockey';
import SportsSoccer from '@material-ui/icons/SportsSoccer';
import SportsKabaddi from '@material-ui/icons/SportsKabaddi';
import SportsBasketball from '@material-ui/icons/SportsBasketball';
import Pool from '@material-ui/icons/Pool';
import SportsCricket from '@material-ui/icons/SportsCricket';
import SportsHockey from '@material-ui/icons/SportsHockey';

const useStyles = makeStyles(({palette, ...theme}) => ({
    wrapper: {
        position: "relative",
        "&::after": {
            content: '" "',
            display: "block",
            position: "absolute",
            height: "100%",
            width: "100vw",
            top: 0,
            left: "calc(-100vw + 50%)",
            background: palette.primary.main,
            opacity: 0.1,
            zIndex: -1,
        },
        "&::before": {
            content: '" "',
            display: "block",
            position: "absolute",
            height: "100%",
            width: "100vw",
            top: 0,
            right: "calc(-100vw + 50%)",
            background: palette.primary.main,
            opacity: 0.1,
            zIndex: -1,
        },
    },
}));

const Features6 = () => {
    const classes = useStyles();

    const isMobile = useMediaQuery("(max-width: 768px");

    return (
        <section className="section" id="">
            <div className="container">
                <div className={clsx("text-center mx-auto", classes.wrapper)}>
                    <h1 className="mt-0 mb-7 font-normal text-40">Benefits:</h1>
                    <p className="flex justify-center flex-wrap">
                        <div className="flex items-center">
                            <Icon className="" color="primary">
                                filter_tilt_shift
                            </Icon>
                            <p className="my-2">
                                Build your sport portfolio
                            </p>
                        </div>

                        <div className="flex items-center">
                            <Icon className="ml-4 mr-1" color="primary">
                                filter_tilt_shift
                            </Icon>
                            <p className="my-2">
                                Connect with the world of sports
                            </p>
                        </div>

                        <div className="flex items-center">
                            <Icon className="ml-4 mr-1" color="primary">
                                filter_tilt_shift
                            </Icon>
                            <p className="my-2">
                                Learn and grow as an athlete with mental development tools
                            </p>
                        </div>

                        <div className="flex items-center">
                            <Icon className="ml-4 mr-1" color="primary">
                                filter_tilt_shift
                            </Icon>
                            <p className="my-2">
                                Market your skills
                            </p>
                        </div>

                        <div className="flex items-center">
                            <Icon className="ml-4 mr-1" color="primary">
                                filter_tilt_shift
                            </Icon>
                            <p className="my-2">
                                Track your physical & emotional progression
                            </p>
                        </div>

                        <div className="flex items-center">
                            <Icon className="ml-4 mr-1" color="primary">
                                filter_tilt_shift
                            </Icon>
                            <p className="my-2">
                                Enhanced mental involvement/development
                            </p>
                        </div>

                        <div className="flex items-center">
                            <Icon className="ml-4 mr-1" color="primary">
                                filter_tilt_shift
                            </Icon>
                            <p className="my-2">
                                Deepen understanding of yourself
                            </p>
                        </div>

                        <div className="flex items-center">
                            <Icon className="ml-4 mr-1" color="primary">
                                filter_tilt_shift
                            </Icon>
                            <p className="my-2">
                                Building positive habits and routines
                            </p>
                        </div>

                        <div className="flex items-center">
                            <Icon className="ml-4 mr-1" color="primary">
                                filter_tilt_shift
                            </Icon>
                            <p className="my-2">
                                Full progression tracker
                            </p>
                        </div>

                    </p>
                    {/*<p className="max-w-400 mx-auto mb-8">
                        *New sports will be added soon, if you wish to see your sport added please submit a request
                        <a target="_blank" href="https://forms.gle/vAgFW9Q22b2BU4a29" style={{color:"blue"}}> here</a>
                    </p>
                    <div className="flex justify-center flex-wrap">
                        <Tooltip title="Soccer" arrow>
                            <SportsSoccer fontSize="large" className="mr-3"/>
                        </Tooltip>
                        <Tooltip title="Judo" arrow>
                            <SportsKabaddi fontSize="large" className="mr-3"/>
                        </Tooltip>
                        <Tooltip title="Basketball" arrow>
                            <SportsBasketball fontSize="large" className="mr-3"/>
                        </Tooltip>
                        <Tooltip title="Swimming" arrow>
                            <Pool fontSize="large" className="mr-3"/>
                        </Tooltip>
                        <Tooltip title="Cricket" arrow>
                            <SportsCricket fontSize="large" className="mr-3"/>
                        </Tooltip>
                        <Tooltip title="Hockey" arrow>
                            <SportsHockey fontSize="large" className="mr-3"/>
                        </Tooltip>

                    </div>*/}
                </div>
            </div>
        </section>
    );
};

export default Features6;
