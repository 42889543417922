import React, {useEffect} from "react";
import {scrollTo} from "../utils";
import TopBar4 from "./sections/da/TopBar4";
import Intro4 from "./sections/da/Intro4";
import Features4 from "./sections/da/Features4";
import Features5 from "./sections/da/Features5";
import Features6 from "./sections/da/Features6";
import Features2 from "./sections/da/Features2";
import Footer2 from "./sections/da/Footer2";
import {isMobile} from "react-device-detect";
import Banner from "./sections/Banner";
import { useMediaQuery } from "@material-ui/core";

const Pc = () => {
    useEffect(() => {
        scrollTo("root");
    });

    const mobile = useMediaQuery("(max-width: 768px");


    return (
        <div className="landing">
            <TopBar4/>
            {isMobile || mobile ? 
                <>
                    <Banner/>
                </>
             :
              <>
              </>
            }
            <Intro4/>
            <Features2/>
            <Features4/>
            <Features5/>
            <Features6/>
            <Footer2/>
        </div>
    );
};

export default Pc;
