import React from "react";
import {
    Route,
    Switch,
    BrowserRouter as Router,
    Redirect,
} from "react-router-dom";
import "react-perfect-scrollbar/dist/css/styles.css";
import {MuiThemeProvider} from "@material-ui/core/styles";
import Scrollbar from "react-perfect-scrollbar";
import {Theme} from "./theme";
import GlobalCss from "./styles/jss/GlobalCss";
import Pc from "./home/Pc";
import Terms from "./home/Terms";
import TermsWeb from "./home/TermsWeb";
import Policy from "./home/Policy";
import PolicyWeb from "./home/PolicyWeb";

function App() {
    return (
        <MuiThemeProvider theme={Theme}>
            <GlobalCss>
                <Scrollbar
                    className="h-full-screen scrollable-content"
                    option={{suppressScrollX: true}}
                >
                    <Router basename="/">
                        <Switch>
                            <Route path="/terms" component={TermsWeb}/>
                            <Route path="/terms-and-condition-XpressDOTS" component={TermsWeb}/>
                            <Route path="/privacy-policy" component={PolicyWeb}/>
                            <Route path="/privacy-policy-XpressDOTS" component={PolicyWeb}/>
                            <Route path="/" component={Pc}/>
                            <Route component={Error}/>
                        </Switch>
                    </Router>
                </Scrollbar>
            </GlobalCss>
        </MuiThemeProvider>
    );
}

export default App;
